import React from "react"
import { Link } from "gatsby"

import "./works.css";

import Slash_left from '../../images/top/slash_left.png';
import Slash_right from '../../images/top/slash_right.png';
import Write from '../../images/top/works_w.png';
import Call from '../../images/top/works_c.png';
import Look from '../../images/top/works_l.png';

const Works = ({ atDetail = false }) => (
  <div className={`works ${atDetail ? "at-detail" : ""}`}>
    <div className="title">
      <div className="sub">
        <p>
          <span>＼</span>
          <span>スルッと理想を叶える！</span>
          <span className="only-mb">／</span>
        </p>
        <p>
          <span>おなまえ</span>
          <span className="registerd-sign">&#174;</span>
          <span>3つのワーク</span>
          <span className="only-pc">／</span>
        </p>
      </div>
      <h1>
        <span>1DAYトレーニングWCLワーク</span>
      </h1>
    </div>
    <ul className="menus">
      <li>
        <div className="icon">
          <img alt="write" src={Write} className="scale-to-fit" />
        </div>
        <div className="text">
          <p className="head">
            <span>「書く」</span>
            で今の自分がハッキリする
          </p>
          <p className="body">
            <p>思考が整い、書き換えていく。</p>
            <p><span className="emp">自分の理想を叶える私になる</span></p>
          </p>
          <div className="point">
            <div className="ja">
              <p><img alt="left" src={Slash_left} className="scale-to-fit" /></p>
              <h4>思考</h4>
              <p><img alt="right" src={Slash_right} className="scale-to-fit" /></p>
            </div>
            <span className="en">Think</span>
          </div>
        </div>
      </li>
      <li>
        <div className="icon">
          <img alt="call" src={Call} className="scale-to-fit" />
        </div>
        <div className="text">
          <p className="head">
            <span>「呼ぶ」</span>
            で愛情が伝わる
          </p>
          <p className="body">
            <p>認めて許し、安心感を得て</p>
            <p><span className="emp">自分をフル活用できる私になる</span></p>
          </p>
          <div className="point">
            <div className="ja">
              <p><img alt="left" src={Slash_left} className="scale-to-fit" /></p>
              <h4>感情</h4>
              <p><img alt="right" src={Slash_right} className="scale-to-fit" /></p>
            </div>
            <span className="en">Emotion</span>
          </div>
        </div>
      </li>
      <li>
        <div className="icon">
          <img alt="look" src={Look} className="scale-to-fit" />
        </div>
        <div className="text">
          <p className="head">
            <span>「見る」</span>
            で自分が愛おしくなる
          </p>
          <p className="body">
            <p>おなまえメソッド<span className="registerd-sign">&#174;</span>独自の分析方法で、</p>
            <p><span className="emp">自分に夢中になる私になる</span></p>
          </p>
          <div className="point">
            <div className="ja">
              <p><img alt="left" src={Slash_left} className="scale-to-fit" /></p>
              <h4>行動</h4>
              <p><img alt="right" src={Slash_right} className="scale-to-fit" /></p>
            </div>
            <span className="en">Action</span>
          </div>
        </div>
      </li>
    </ul>
    <p className="read">「名前」は、理想通りの自分の人生を叶え、確立させてくれます。本来の自分が生きたいと望む人生や求めている人生、未来を知っていく事で、魂から願っている「理想の未来」が見つかります！</p>
    { atDetail ? 
      null
      :
      <div className="btn-holder">
        <Link to='/detail3'>WCLワーク詳細はこちら</Link>
      </div>
    }
  </div>
)

export default Works
