import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./detail.css";

import Motif_left from '../images/service/motif_left.png'
import Motif_right from '../images/service/motif_right.png'

import Select from '../components/service/select_member';
import Works from "../components/common/works"

import Policy from '../components/common/policy';

const Detail3 = () => {
  const [ visibleoverlay, setVisibleoverlay ] = useState(false);

  const handleSelectMember = () => {
    setVisibleoverlay(true);
  }

  const handleClose = () => {
    setVisibleoverlay(false);
  }

  return (
    <Layout>
      <SEO title="おなまえ入門トレーニング" />
      <div className="detail-page">
        <div className="wrapper">
          <div className="title ff-min">
            <h4>first step</h4>
            <h2>
              <span>
                おなまえ
                <span className="registerd-sign">&#174;</span>
              </span>
              <span>
                入門トレーニング
              </span>
            </h2>
            <div className="subtitle">
              <p>
                <span>＼</span>
                <span>スルッと理想を叶える！</span>
                <span className="only-mb">／</span>
              </p>
              <p>
                <span>おなまえ</span>
                <span className="registerd-sign">&#174;</span>
                <span>3つのワーク</span>
                <span className="only-pc">／</span>
              </p>
            </div>
          </div>
          <div className="detail-title">
            <ul>
              <li className="image">
                <img alt=" " src={Motif_left} className="scale-to-fit" />
              </li>
              <li className="text ff-min">
                <span>講座内容</span>
              </li>
              <li className="image">
                <img alt=" " src={Motif_right} className="scale-to-fit" />
              </li>
            </ul>
          </div>
          <div className="information narrow ff-min">
            <ul>
              <li>
                少人数グループ<br />
                90分レッスン<br />
                参加費：5,500円（税込）
              </li>
            </ul>
            <div className="benefits">
              <h4>&mdash;特典&mdash;</h4>
              <p>5DAYS動画配信</p>
              <p>添削フォロー会</p>
            </div>
          </div>
          <Works atDetail={true} />
          <Policy />
          <div className="btn-holder">
            <span role="button" tabIndex={0} onClick={handleSelectMember} onKeyDown={handleSelectMember}>講師を選択する</span>
          </div>
        </div>
      </div>
      { visibleoverlay ?
        <Select close={handleClose} subject='講座のお申し込み' isNotExistCaptain={true} />
        :
        null
      }
    </Layout>
  )
}

export default Detail3
