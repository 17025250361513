import React from "react"

import File from '../../images/common/policy.pdf'

const Policy = () => (
  <div style={{
    textAlign: 'center',
    letterSpacing: '2px',
    fontSize: '1rem',
    paddingBottom: '3rem',
  }}>
    <a href={File} target="_blank" rel="noreferrer">
      ご利用規約
    </a>
  </div>
);

export default Policy;
