import React from "react"

import "./select_member.css";

const SelectMember = ({ close, subject = '個人鑑定の申込み', isNotExistCaptain = false }) => {

  const members = [
    {
      name: '星名 美利',
      email: 'hello.millyhoshina@gmail.com',
    },
    {
      name: '山本 久美子',
      email: 'kumiko.yamamoto935@gmail.com',
    },
    {
      name: '叶 結月',
      email: 'kaiun.55.yuiki@gmail.com',
    },
    {
      name: '片瀬 絵未里',
      email: 'emiri.loveonamae@gmail.com',
    },
    {
      name: '武内 愛',
      email: 'aimantenkamifude@gmail.com',
    },
  ];

  const renderList = () => {
    return members.map(member => {

      if (isNotExistCaptain && member.email === 'hello.millyhoshina@gmail.com') {
        return null;
      } else {
        const mailTo = `mailto:${member.email}?subject=${subject}`
        return (
          <li>
            <a href={ mailTo }>{ member.name }</a>
          </li>
        )
      }
    })
  }

  return (
    <div className="overlay">
      <div className="content">
        <ul>
          { renderList() }
        </ul>
        <div role="button" tabIndex={0} className="close" onClick={close} onKeyDown={close}>&times; Close</div>
      </div>
    </div>
  )
}

export default SelectMember
